import { useEffect } from 'react';

const Hessenklebt = () => {
  useEffect(() => {
    localStorage.setItem('club_id', 692);
    localStorage.setItem('club_name', 'Hessen klebt');

    window.location.assign('/');
  });

  return null;
};

export default Hessenklebt;
